import "./productsdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows, productColumns,orderColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  collection,
   deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
  query, orderBy
} from "firebase/firestore";
import { db } from "../../utils/firebase.js";
import ModalEdit from "../../../src/components/ModalEdit.tsx";
 
const ProductsDatatable = () => {
  const [data, setData] = useState([]);
  const [isEditting, setIsEditting] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTransfering, setIsTransfering] = useState(false);
  const [statusSelectedItem, setStatusSelectedItem] = useState('');
  const [productId, setProductId] = useState('');
  const [selectedProduct, ] = useState('');

  const openModalEdit = () => setIsEditting(true);
  const closeModalEdit = () => setIsEditting(false);

  useEffect(() => {

    const q = query(collection(db, "products"), orderBy("timeStamp", "desc"));

    // Set up a listener with onSnapshot
    const unsub = onSnapshot(q, (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setData(list);
    }, (error) => {
      console.error(error);
    });

 

    return () => {
      unsub();
    };
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "products", id));
      setData(data.filter((item) => item.id !== id));
    } catch (err) {
      console.log(err);
    }
  };
   const handleUpdateStock = async (id) => {
    try {
      if(productId){
      //await deleteDoc(doc(db, "products", id));
      //setData(data.filter((item) => item.id !== id));
      const docRef = doc(db, 'products', productId);

      // Update the timestamp field with the value from the server
      const updateTimestamp = await updateDoc(docRef, {
        instock: statusSelectedItem
      });
      setProductId(""); 
      }

      closeModalEdit();
    } catch (err) {
      console.log(err);
    }
  };
  
  const hanldeClickDropDown = (productId) => {
    openModalEdit();
    setProductId(productId);
  };

  const setStatusSelected = (selectedStatus) =>{
    setStatusSelectedItem(selectedStatus);
  }
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
           <div  className="viewButton" onClick={() => hanldeClickDropDown(params.row.id)}> Update Stock
            </div>    
            <ModalEdit show={isEditting}  onCloseModalEdit={closeModalEdit} 
            onModalSubmit={handleUpdateStock} onStatusSelected={setStatusSelected} />
            {/* <Link  to={`/addproducts/id= ${params.row.id}`}  style={{ textDecoration: "none" }}>
              <div className="viewButton">Edit</div>
            </Link> */}
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (                                                  
    <div className="datatable">
      <div className="datatableTitle">
        Add New Product
        <Link to="/addproducts" className="link">
          Add New
        </Link>
      </div>

      <DataGrid
        className="datagrid"
        rows={data}
        columns={productColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        disableRowSelectionOnClick
       />
    </div>
  );
};

export default ProductsDatatable;
